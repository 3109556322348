<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Fill in the table with the correct responses.</p>

      <table>
        <thead>
          <tr>
            <th style="text-align: left; width: 12%">Reaction Order</th>
            <th style="text-align: left; width: 22%">Integrated Rate Law</th>
            <th style="text-align: left; width: 22%">Linear Plot</th>
            <th style="text-align: left; width: 22%">Rate constant (k) from linear plot</th>
            <th style="text-align: left; width: 22%">
              Half-life
              <stemble-latex content="$\text{t}_{1/2}$" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold">a) 0<sup>th</sup></td>
            <td>
              <v-select
                v-model="inputs.input1"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input2"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input3"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input4"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">b) 1<sup>st</sup></td>
            <td>
              <v-select
                v-model="inputs.input5"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input6"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input7"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input8"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">c) 2<sup>nd</sup></td>
            <td>
              <v-select
                v-model="inputs.input9"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input10"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input11"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input12"
                style="display: inline-block; max-width: 200px"
                class="ml-1"
                :items="items"
                item-text="text"
                item-value="value"
                label="Select option:"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ3S1Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
        input11: null,
        input12: null,
      },
      items: [
        {text: '$1/\\text{A}_\\text{t} = \\text{kt} + 1/\\text{A}_0$', value: 'a'},
        {text: '$\\text{ln A vs time}$', value: 'b'},
        {text: '$\\text{Negative of the slope (-m)}$', value: 'c'},
        {text: '$\\text{log A vs time}$', value: 'd'},
        {text: '$\\text{A}_\\text{t} = \\text{-kt} + \\text{A}_0$', value: 'e'},
        {text: '$\\text{A}_\\text{t}/\\text{k}$', value: 'f'},
        {text: '$\\text{A}_0/2~\\text{k}$', value: 'g'},
        {text: '$\\text{y-intercept}$', value: 'h'},
        {text: '$\\text{A vs time}$', value: 'i'},
        {text: '$\\text{Slope (m)}$', value: 'j'},
        {text: '$\\text{A}_\\text{t} = \\text{kt} + \\text{A}_0$', value: 'k'},
        {text: '$\\text{1/A vs time}$', value: 'l'},
        {text: '$\\text{ln A}_\\text{t} = \\text{-kt} + \\text{ln} \\text{A}_0$', value: 'm'},
        {text: '$\\text{ln(2)/k}$', value: 'n'},
        {text: '$\\text{1/A}_0~\\text{k}$', value: 'o'},
      ],
    };
  },
};
</script>
<style scoped></style>
