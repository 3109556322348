var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v("Fill in the table with the correct responses.")]),_c('table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left","width":"12%"}},[_vm._v("Reaction Order")]),_c('th',{staticStyle:{"text-align":"left","width":"22%"}},[_vm._v("Integrated Rate Law")]),_c('th',{staticStyle:{"text-align":"left","width":"22%"}},[_vm._v("Linear Plot")]),_c('th',{staticStyle:{"text-align":"left","width":"22%"}},[_vm._v("Rate constant (k) from linear plot")]),_c('th',{staticStyle:{"text-align":"left","width":"22%"}},[_vm._v(" Half-life "),_c('stemble-latex',{attrs:{"content":"$\\text{t}_{1/2}$"}})],1)])]),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("a) 0"),_c('sup',[_vm._v("th")])]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1)]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("b) 1"),_c('sup',[_vm._v("st")])]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input8),callback:function ($$v) {_vm.$set(_vm.inputs, "input8", $$v)},expression:"inputs.input8"}})],1)]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("c) 2"),_c('sup',[_vm._v("nd")])]),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input9),callback:function ($$v) {_vm.$set(_vm.inputs, "input9", $$v)},expression:"inputs.input9"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input10),callback:function ($$v) {_vm.$set(_vm.inputs, "input10", $$v)},expression:"inputs.input10"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input11),callback:function ($$v) {_vm.$set(_vm.inputs, "input11", $$v)},expression:"inputs.input11"}})],1),_c('td',[_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input12),callback:function ($$v) {_vm.$set(_vm.inputs, "input12", $$v)},expression:"inputs.input12"}})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }